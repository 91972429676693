html,
body {
  paddding: 0;
  margin: 0;
}

@font-face {
  font-family: 'bespoke-extrabold-italic';
  src: local('bespoke'),
    url('./fonts/bespoke/BespokeSerif-ExtraboldItalic.woff') format('truetype');
}

@font-face {
  font-family: 'bespoke-medium';
  src: local('bespoke'),
    url('./fonts/bespoke/BespokeSerif-Medium.woff') format('truetype');
}

@font-face {
  font-family: 'bespoke-regular';
  src: local('bespoke'),
    url('./fonts/bespoke/BespokeSerif-Regular.woff') format('truetype');
}

@font-face {
  font-family: 'bespoke-light-italic';
  src: local('bespoke'),
    url('./fonts/bespoke/BespokeSerif-LightItalic.woff') format('truetype');
}
